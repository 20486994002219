<template>
  <component :is="item === undefined ? 'div' : 'div'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-card>
              <b-form>
                <b-tabs>
                  <b-tab
                    v-for="language in languages"
                    :key="language.code"
                  >
                    <template #title>
                      <b-img
                        :src="require('@/assets/images/flags/' + language.code + '.svg')"
                        height="16"
                        width="16"
                        class="mr-1"
                      />
                      <span class="d-none d-sm-inline">{{ language.title }}</span>
                    </template>
                    <b-row>
                      <b-col
                        cols="24"
                        md="12"
                      >
                        <b-form-group
                          :label="$t('form.title.label')"
                          :label-for="'title.' + language.code"
                        >
                          <b-form-input
                            :id="'title.' + language.code"
                            v-model="item.title[language.code]"
                            :state="errors && errors['title.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                            {{ errors['title.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="$t('form.status.label')"
                      label-for="status"
                      :state="errors && errors.status ? false : null"
                    >
                      <v-select
                        id="status"
                        v-model="item.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="status"
                      />
                      <b-form-invalid-feedback v-if="errors && errors.status">
                        {{ errors.status[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="$t('form.place.label')"
                      label-for="place"
                      :state="errors && errors.place ? false : null"
                    >
                      <v-select
                        id="place"
                        v-model="item.place"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="placeOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="place"
                      />
                      <b-form-invalid-feedback v-if="errors && errors.place">
                        {{ errors.place[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="$t('form.position.label')"
                      label-for="position"
                    >
                      <b-form-input
                        id="position"
                        v-model="item.position"
                        :state="errors && errors.position ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors.position">
                        {{ errors.position[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Action Buttons -->
                <b-row>
                  <b-col cols="6">
                    <b-button
                      variant="primary"
                      block
                      @click="saveList"
                    >
                      {{ $t('general.save') }}
                    </b-button>
                  </b-col>
                  <b-col cols="6">
                    <b-button
                      type="button"
                      variant="outline-secondary"
                      block
                      :to="{ name: 'navigations-index' }"
                    >
                      {{ $t('general.cancel') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <b-card>
              <b-row>
                <b-col
                  v-if="item.place === 'catalog'"
                  cols="12"
                  md="8"
                >
                  <b-button
                    v-if="$ability.can('store', 'navigations')"
                    variant="info"
                    block
                    class="mb-2"
                    @click="runCategoryImport"
                  >
                    <span class="text-nowrap">Імпортувати з категорій</span>
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                  :md="item.place === 'catalog' ? 4 : 12"
                >
                  <b-button
                    v-if="$ability.can('store', 'navigations')"
                    variant="warning"
                    block
                    class="mb-2"
                    @click="openModalForm"
                  >
                    <span class="text-nowrap">{{ $t('general.add') }}</span>
                  </b-button>
                </b-col>
              </b-row>
              <div class="halo-tree">
                <draggable
                  v-model="items"
                  group="tree"
                >
                  <div
                    v-for="level1 in items"
                    :key="level1.id"
                    class="li"
                  >
                    <div class="tree-node-el">
                      <div>
                        <button
                          class="btn btn-primary btn-sm"
                          :disabled="!level1.children.length"
                          @click="level1.expanded = !level1.expanded"
                        >
                          <feather-icon
                            :icon="!level1.expanded ? 'Maximize2Icon' : 'Minimize2Icon'"
                            size="14"
                          />
                        </button>
                        <span>{{ level1.title }}</span>
                      </div>
                      <div>
                        <button
                          class="btn btn-primary btn-sm"
                          @click="openModalForm(level1)"
                        >
                          <feather-icon
                            icon="EditIcon"
                            size="14"
                            class-name="mr-50"
                          />
                        </button>
                        <button
                          class="btn btn-danger btn-sm"
                          @click="destroy(level1.id)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            size="14"
                            class-name="mr-50"
                          />
                        </button>
                      </div>
                    </div>

                    <div
                      v-if="level1.children && level1.children.length"
                      class="halo-tree"
                      :class="!level1.expanded ? 'hidden' : ''"
                    >
                      <draggable
                        v-model="level1.children"
                        group="tree"
                      >
                        <div
                          v-for="level2 in level1.children"
                          :key="level2.id"
                          class="li"
                        >
                          <div class="tree-node-el">
                            <div>
                              <button
                                class="btn btn-primary btn-sm"
                                :disabled="!level2.children.length"
                                @click="level2.expanded = !level2.expanded"
                              >
                                <feather-icon
                                  :icon="!level2.expanded ? 'Maximize2Icon' : 'Minimize2Icon'"
                                  size="14"
                                />
                              </button>
                              <span>{{ level2.title }}</span>
                            </div>
                            <div>
                              <button
                                class="btn btn-primary btn-sm"
                                @click="openModalForm(level2)"
                              >
                                <feather-icon
                                  icon="EditIcon"
                                  size="14"
                                  class-name="mr-50"
                                />
                              </button>
                              <button
                                class="btn btn-danger btn-sm"
                                @click="destroy(level2.id)"
                              >
                                <feather-icon
                                  icon="Trash2Icon"
                                  size="14"
                                  class-name="mr-50"
                                />
                              </button>
                            </div>
                          </div>

                          <div
                            v-if="level2.children && level2.children.length"
                            class="halo-tree"
                            :class="!level2.expanded ? 'hidden' : ''"
                          >
                            <draggable
                              v-model="level2.children"
                              group="tree"
                            >
                              <div
                                v-for="level3 in level2.children"
                                :key="level3.id"
                                class="li"
                              >
                                <div class="tree-node-el">
                                  <span>{{ level3.title }}</span>
                                  <div>
                                    <button
                                      class="btn btn-primary btn-sm"
                                      @click="openModalForm(level3)"
                                    >
                                      <feather-icon
                                        icon="EditIcon"
                                        size="14"
                                        class-name="mr-50"
                                      />
                                    </button>
                                    <button
                                      class="btn btn-danger btn-sm"
                                      @click="destroy(level3.id)"
                                    >
                                      <feather-icon
                                        icon="Trash2Icon"
                                        size="14"
                                        class-name="mr-50"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </draggable>
                          </div>
                        </div>
                      </draggable>
                    </div>
                  </div>
                </draggable>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <modal-form
          :opened-modal-form.sync="openedModalForm"
          :item="itemModalForm"
          @refetch-data="fetchData"
        />
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ModalForm from './ModalForm.vue'

export default {
  components: {
    ModalForm,
  },
  mixins: [GlobalMixin],
  data() {
    return {
      item: null,
      openedModalForm: false,
      itemModalForm: {
        title: { uk: null, ru: null },
        href: null,
        parent_id: null,
        avatar: null,
        blank: null,
        position: null,
        navigation_id: this.$route.params.id,
      },
      languages: [],
      searchword: '',
      items: [],
      apiUrl: process.env.VUE_APP_APP_URL,
      placeOptions: [
        { label: this.$t('general.places.header'), value: 'header' },
        { label: this.$t('general.places.footer'), value: 'footer' },
        { label: this.$t('general.places.catalog'), value: 'catalog' },
        { label: this.$t('general.places.slider'), value: 'slider' },
      ],
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get(`/api/admin/navigations/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'navigations-index' })
        }
      })

    if (!Object.keys(this.items).length) {
      await this.$http.get('/api/admin/navigation_items', {
        params: {
          navigation_id: this.item.id,
        },
      })
        .then(response => {
          this.items = response.data
        })
    }
  },
  methods: {
    destroy(id) {
      this.confirm(() => {
        this.$http.delete(`/api/admin/navigation_items/${id}`)
          .then(() => this.fetchData())
          .catch(error => {
            this.fetchData()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      })
    },
    runCategoryImport() {
      this.confirm(() => {
        this.$http.post(`/api/admin/navigations/${router.currentRoute.params.id}/import`)
          .then(() => {
            this.fetchData()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Imported',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.fetchData()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        text: 'При імпорті буде побудовано меню відповідно до дерева категорій. Запустити?',
      })
    },
    fetchData() {
      this.$http.get('/api/admin/navigation_items', {
        params: {
          navigation_id: this.item.id,
        },
      })
        .then(response => {
          this.items = response.data
        })
    },
    onSubmit() {
      const data = {
        status: this.item.status,
        title: this.item.title,
        place: this.item.place,
        position: this.item.position,
      }

      this.$http.put(`/api/admin/navigations/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'navigations-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        status: item.status,
        place: item.place,
        position: item.position,
        title: {},
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
      })

      return data
    },
    saveList() {
      const data = { items: this.mapCat(this.items) }
      this.$http.post('/api/admin/sort_navigation_items', data)
        .then(() => {
          this.onSubmit()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Успішно',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    mapCat(items, parent_id = null) {
      return items.map(item => ({
        id: item.id,
        parent_id,
        children: this.mapCat(item.children, item.id),
      }))
    },
    transformItemData(item = null) {
      let data = {
        avatar: null,
        parent_id: null,
        blank: false,
        position: 0,
        href: '',
        navigation_id: this.$route.params.id,
        title: {},
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
      })
      if (item) {
        data = {
          id: item.id,
          avatar: this.$options.filters.singleMedia(item.avatar),
          parent_id: item.parent_id,
          blank: item.blank,
          position: item.position,
          href: item.href,
          navigation_id: this.$route.params.id,
          title: {},
        }

        _.each(this.languages, language => {
          const translation = _.find(item.translations, { locale: language.code })
          data.title[language.code] = translation ? translation.title : null
        })
      }

      return data
    },
    openModalForm(data = null) {
      this.itemModalForm = this.transformItemData(data)
      this.openedModalForm = true
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/tree.scss";
</style>
